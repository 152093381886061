@import url('https://fonts.googleapis.com/css2?family=Lumanosimo&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.wrapper {
    background-color: rgb(2, 2, 36);
}

.heading {
    font-family: "Lumanosimo", cursive;
    font-weight: 400;
    font-style: normal;
}

.styled-input {
    outline: 3px solid #60a5fa;
    padding: 6px;
    font-size: 20px;
    border: none;
    border-radius: 8px;
    width: 350px;
}

.styled-input:focus {
    outline-width: 4px;
}

.spinner {
    width: 80px;
    height: 80px;
    display: grid;
}

.spinner::before,
.spinner::after {
    content: "";
    grid-area: 1/1;
    --c: radial-gradient(farthest-side, #BFE4F4 92%, #0000);
    background:
        var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    animation: s2 1s infinite;
}

.spinner::before {
    margin: 4px;
    filter: hue-rotate(45deg);
    background-size: 8px 8px;
    animation-timing-function: linear
}

@keyframes s2 {
    100% {
        transform: rotate(.5turn)
    }
}